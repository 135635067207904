<template>
  <div class="middle-box text-center animated fadeInDown">
    <div class="row">
        <div class="col-lg-6" v-if="config.apps.google-authenticator">
          <div class="ibox">
            <div class="ibox-title">
              <h4>Google Authenticator</h4>
            </div>
            <div class="ibox-content">
              <img src="@public/img/authenticator/google-authenticator.jpg" class="app-img"/>
              <a v-if="config.apps.google-authenticator.android" :href="config.apps.google-authenticator.android" style="display:inline-block;overflow:hidden;width:155px;height:60px;">
                <img alt='Get it on Google Play' :src="config.badges.android" style="width:155px"/>
              </a>

              <a v-if="config.apps.google-authenticator.iOS" :href="config.apps.google-authenticator.iOS" :style="'display:inline-block;overflow:hidden;background:url('+config.badges.iOS+') no-repeat;width:135px;height:40px;margin:10px;'"></a>
            </div>
          </div>
        </div>
        <div class="col-lg-6" v-if="config.apps.microsoft-authenticator">
          <div class="ibox">
            <div class="ibox-title">
              <h4>Microsoft Authenticator</h4>
            </div>
            <div class="ibox-content">
              <img src="@public/img/authenticator/microsoft-authenticator.jpg" class="app-img"/>
              <a v-if="config.apps.microsoft-authenticator.android" :href="config.apps.microsoft-authenticator.android" style="display:inline-block;overflow:hidden;width:155px;height:60px;">
                <img alt='Get it on Google Play' :src="config.badges.android" style="width:155px"/>
              </a>

              <a v-if="config.apps.microsoft-authenticator.iOS" :href="config.apps.microsoft-authenticator.iOS" :style="'display:inline-block;overflow:hidden;background:url('+config.badges.iOS+') no-repeat;width:135px;height:40px;margin:10px;'"></a>
            </div>
          </div>
        </div>
    </div>
  </div>

</template>

<style>
  .middle-box {
    max-width: 800px;
  }

  .app-img {
    width: 200px;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:15px;
    border-radius: 15px;
  }

  .ibox-title {
    padding-left:15px;
    padding-right:15px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';

@Component({
  components: {},
  computed: {}
})
export default class AuthenticatorLinks extends mixins(GenericPage) {

  config:any = {
    apps : {},
    badges : {}
  };

  mounted() {
    
  }

  created() {
    // We need to configuration
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/auth/authenticator-app-config', options).then((response:any) => {
      if(response.authenticatorAppConfig) {  
        this.config = response.authenticatorAppConfig;
      }
    });
  }
  
}
</script>