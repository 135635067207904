import { render, staticRenderFns } from "./AuthenticatorLinks.vue?vue&type=template&id=0efbf262"
import script from "./AuthenticatorLinks.vue?vue&type=script&lang=ts"
export * from "./AuthenticatorLinks.vue?vue&type=script&lang=ts"
import style0 from "./AuthenticatorLinks.vue?vue&type=style&index=0&id=0efbf262&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports